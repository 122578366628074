@font-face {
  font-family: 'BTBrikFont';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: local('BTBrikFont'), url('./fonts/Brik_Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'BTBrikFont';
  font-style: normal;
  font-weight: normal;
  font-stretch: ultra-expanded;
  src: local('BTBrikFont'), url('./fonts/Brik_4XL.woff2') format('woff2');
}

@font-face {
  font-family: 'BTBrikFont';
  font-style: normal;
  font-weight: normal;
  font-stretch: expanded;
  src: local('BTBrikFont'), url('./fonts/Brik_3XL.woff2') format('woff2');
}

@font-face {
  font-family: 'BTBrikFont';
  font-style: normal;
  font-weight: normal;
  font-stretch: 150%;
  src: local('BTBrikFont'), url('./fonts/Brik_XXL.woff2') format('woff2');
}

@font-face {
  font-family: 'BTBrikFont';
  font-style: normal;
  font-weight: normal;
  font-stretch: 100%;
  src: local('BTBrikFont'), url('./fonts/Brik_XL.woff2') format('woff2');
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 6px 0 7px;
  height: 2em;
  background: none;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid var(--colors-contrast-text-seconday);
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.header-st1 {
  font-family: Space Grotesk;
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitle-st1 {
  font-family: Space Grotesk;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.font-xl {
  font-size: large;
  text-align: center;
}

.padding {
  padding: 2rem;
}

.m-t {
  margin-top: 1rem;
}

@media (max-width: 640px) {
  .padding {
    padding: 1rem;
  }
}

.broder-divider {
  border: 1px solid;
}

.flex-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.flex-container-2 {
  height: 100%;
  width: 90%;
  display: flex;
  margin: auto;
}
.display-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.center-vertical {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-horizental {
  align-items: center;
  justify-content: center;
}

.start-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.start-horizental {
  align-items: flex-start;
  justify-content: flex-start;
}
.end-horizental {
  align-items: flex-end;
  justify-content: space-between;
}

.js-c-c {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.gap-1 {
  gap: 1rem;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 2rem;
}

.txt-align-start {
  text-align: start;
}

.marginBottom-1 {
  margin-bottom: 1vh;
}

.m-top-1 {
  margin-top: 1vh;
}
.m-r-1 {
  margin-right: 1vw;
}

.marginBottom-2 {
  margin-bottom: 2vh;
}

.mb-1 {
  margin-bottom: 1rem;
}

.m-1 {
  margin: 1rem;
}

.m-05 {
  margin: 0.5rem;
}

.pointer {
  cursor: pointer;
}

:root {
  --colors-primary: #060a06;
  --colors-secondary: #50d860;
  --colors-secondary-hover: #94ffa1;
  --color-secondary-hover-2: #c8ffcf;
  --colors-contrast-text-primary: #ffffff;
  --colors-contrast-text-seconday: #060a06;
  --colors-data-table-head: #f0f6f4;
  --color-hover-charcol: #3d3d3d;
  --color-orange-high: #ea6638;
  --color-bg-dark-1: #cfdcdc;
  --color-header-empasize: #5a50e2;
  --color-status-failure: #ff8884;
  --color-status-warning: #cbfdf9;
  --color-status-warning-1: #f7a12c;
  --color-background-page: #e5e5e5;
  --color-alert-orange: #ff875c;
  --color-alert-red: #fc4f59;
  --color-bright-red: #e10600;
  --color-bright-orange: #ff5733;
  --locad-aqua-default: #82fff6;
  --locad-aqua-high: #04e4d4;
  --locad-aqua-low: #cbfdf9;
  --locad-violet-default: #847bff;
  --locad-violet-low: #c2bdff;
  --locad-violet-high: #5a50e2;
}

.on-hover-border {
  border: 2px solid white;
}

.on-hover-border:hover {
  border: 2px solid var(--colors-secondary);
}

/* Theme starts here  */
body[data-theme='dark'] {
  --colors-primary: #060a06;
  --colors-secondary: #50d860;
  --colors-contrast-text-primary: #ffffff;
  --colors-contrast-text-seconday: #060a06;
}

body[data-theme='light'] {
  --colors-primary: lightpink;
  --colors-background: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.icons-wrapper-box-sidebar::-webkit-scrollbar {
  display: none;
}

.react-datepicker__input-container input {
  display: block;
  width: 100%;
  padding: 6px 0 7px;
  height: 2em;
  background: none;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid var(--colors-contrast-text-seconday);
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
}

/* Global scrollbar styles */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: inherit;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 2px solid gray;
}

*::-webkit-scrollbar-corner {
  background: inherit;
}
