svg.MuiSvgIcon-root.MuiSelect-icon {
  color: var(--colors-contrast-text-primary);
}
.editMapLocationContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  gap: 1rem;
  align-items: center;
  padding-left: 1rem;
  margin-top: 1rem;
}
.full-width {
  max-width: 640px;
  grid-column-start: 1;
  grid-column-end: -1;
}

.flex-simple {
  display: flex;
  flex-direction: column;
}

.address {
  grid-column: 1/-1;
  max-width: 640px;
}
