.onboarding-container {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
}

.header-logo {
  min-width: 480px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding-left: 1rem;
}

.onboarding-header-text {
  font-family: Space Grotesk;
  font-weight: lighter;
  text-align: left;
}
.font-32 {
  font-size: 2rem;
}
.font-20 {
  font-size: 1rem;
}

.image-modifier {
  width: 10rem;
}
